import * as React from "react";


import { IEmailSubReportHotDeskLogins } from "../../interfaces/email-reports";
import { Grid } from "@material-ui/core";
import MuiTitle from "../misc/form/MuiTitle";
import VipTimeFieldMui from "../VipTimeFieldMui";
import { useState } from "react";
import MuiToggleBoolControlled from "../misc/form/MuiToggleBoolControlled";

export interface iEmailReportHotDeskLoginsProps {
  data: IEmailSubReportHotDeskLogins;
  submitFunction: (field: string, data: any) => void;
}

const EmailReportHotDeskLogins = ({ data, submitFunction }: iEmailReportHotDeskLoginsProps) => {

  const [state, setState] = useState<IEmailSubReportHotDeskLogins>(() => {
    //  console.log(hotDesks)
    const xState = { ...data };
    if (!xState.startTime) {
      xState.startTime = "08:00:00"
      submitFunction("startTime",  "08:00:00")
    }
    if (!xState.endTime) {
      xState.endTime = "18:00:00"
      submitFunction("endTime", "18:00:00" )
    }
    if (!xState.hotDeskLoginsHideNotLoggedIn) {
      xState.hotDeskLoginsHideNotLoggedIn = false;
      submitFunction("hotDeskLoginsHideNotLoggedIn", false)
    }




    return xState;
  });
  const setField = (field: string, value: any) => {
    console.log("subform", field, value);
    const stateUp: any = { ...state };
    stateUp[field] = value;
    setState(stateUp);
    submitFunction(field, value);
  }
  return <Grid container spacing={2}>

    <Grid item xs={6}>
      <MuiTitle title="Start Time" />
      <VipTimeFieldMui
        field="startTime"
        value={state.startTime ? state.startTime : "08:00:00"}
        updateField={setField}
      />

    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="End Time" />
      <VipTimeFieldMui
        field="endTime"
        value={state.startTime ? state.endTime : "18:00:00"}
        updateField={setField}
      />
    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="Hide agents who did not log in?" />
      <MuiToggleBoolControlled value={state.hotDeskLoginsHideNotLoggedIn} setValue={(ev) => {
        setField("hotDeskLoginsHideNotLoggedIn", !state.hotDeskLoginsHideNotLoggedIn);
      }} />
    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="Show dates?" />
      <MuiToggleBoolControlled value={state.hotDeskLoginsShowDates} setValue={(ev) => {
        setField("hotDeskLoginsShowDates", !state.hotDeskLoginsShowDates);
      }} />
    </Grid>
  </Grid>
}
export default EmailReportHotDeskLogins;
